<template>
  <span class="small" :class="textClass"><i :class="icon"></i> {{ text }}</span>
</template>
<script>
export default {
  name: 'MarketGroupStatus',
  props: {
    status: {
      type: String
    }
  },
  computed: {
    text() {
      switch(this.status) {
        case 'Active':
          return 'ใช้งาน'
        break
        case 'Delete':
          return 'ลบ'
        break
      }
    },
    textClass() {
      switch(this.status) {
        case 'Active':
          return 'text-success'
        break
        case 'Delete':
          return 'text-secondary'
        break
      }
    },
    icon() {
      switch(this.status) {
        case 'Active':
          return 'fas fa-circle'
        break
        case 'Delete':
          return 'far fa-trash-alt'
        break
      }
    }
  }
}
</script>
